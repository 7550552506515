<template>
  <!-- BANNER -->
  <el-container
    class="l-default l-default--with-title"
    :class="{ 'l-default--with-title': !isMindsphere }"
  >
    <div
      :class="{
        'l-default--title': !isMindsphere,
        'l-default--title-ms': isMindsphere,
      }"
    >
      Industrial Vulnerability Manager
    </div>

    <!-- HEADER -->
    <el-header class="l-default__header" ref="navigation">
      <HeaderLayout :windowSize="windowSize" />

      <!-- ALERT BAR -->
      <el-alert
        v-if="env !== 'demo'"
        title="New major version 1.5.0 available"
        type="warning"
        show-icon
        effect="dark"
        description="Please click here to access the new version in a new window. This current version will be deprecated and no longer supported in the near future."
        :closable="false"
        @click.native="navigateToUpdate"
      />
    </el-header>

    <!-- CONTENT -->
    <transition name="el-fade-in" mode="out-in">
      <router-view class="l-default__main" :windowSize="windowSize" />
    </transition>

    <!-- FOOTER -->
    <el-footer class="l-default__footer">
      <FooterLayout :footerClass="footerClass" />
    </el-footer>
  </el-container>
</template>

<style lang="scss">
.l-default {
  height: 100vh;
}

.l-default--with-title .l-default--title,
.l-default--with-title .l-default--title::after {
  display: block;
}

.l-default--with-title > .l-default__header {
  border-top: 4px solid #207edf;
  box-sizing: border-box;
  padding-top: 3px !important;
  height: 140px !important;
}

.l-default--with-title > .l-default__footer {
  padding: 0 !important;
  overflow: auto;
  height: 40px !important;
  width: 100%;
}

.l-default--title {
  display: none;
  position: absolute;
  padding: 3px 20px 3px 30px;
  color: #fafafa;
  left: 0px;
  top: 0px;
  z-index: 1;
  font-weight: 900;
  font-size: 13px;
  background-color: #207edf;
}

.l-default--title-ms {
  display: block;
  position: absolute;
  padding: 3px 20px 3px 30px;
  color: #fafafa;
  left: 0px;
  z-index: 1;
  font-weight: 900;
  font-size: 13px;
  background-color: #207edf;
}

.l-default--title::after {
  position: absolute;
  right: 0;
  top: -4px;
  transform-origin: right bottom;
  transform: rotate(45deg);
  display: none;
  width: 25px;
  height: 25px;
  content: " ";
  background-color: #207edf;
}

.l-default__header {
  background-color: #fff;
  padding: 5px !important;
}

.l-default__alert-bar {
  background-color: #fff;
  padding: 5px !important;
}

.l-default__main {
  height: calc(100vh - 200px);
}

.l-default__footer {
  padding: 0;
}
</style>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import FooterLayout from "./footer.vue";
import HeaderLayout from "./header.vue";

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    HeaderLayout,
    FooterLayout,
  },
  data() {
    return {
      windowSize: {
        width: 0,
        height: 0,
      },
      footerClass: "default",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize, false);
  },
  computed: {
    isMindsphere() {
      return process.env.VUE_APP_ENV === "mindsphere";
    },
  },
  methods: {
    ...mapActions("session", ["selectRealm"]),
    ...mapState("session", ["env"]),
    handleResize() {
      this.windowSize.width = window.innerWidth;
      this.windowSize.height = window.innerHeight;

      this.footerClass = window.matchMedia("(max-width: 900px)").matches
        ? "small-footer"
        : "large-footer";
    },
    navigateToUpdate() {
      window.open("https://app-new.server-ivm.siemens.cloud", "_blank");
    },
  },
});
</script>
